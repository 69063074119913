import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice/authSlice';
import dataSlice from './dataSlice/dataSlice';

const store = configureStore({
    reducer: {
        auth: authSlice,
        data: dataSlice,
    },
   
});
export default store;

  
  