import { useLocation, Outlet } from "react-router-dom";
import Header from "../components/Header/index";
import Sidebar from "../components/Sidebar/index";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const DefaultLayout = () => {
    const location = useLocation();
    const [title, setTitle] = useState();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        let pathTitle;
        if (location?.state?.title) {
            pathTitle = location?.state?.title
        } else {
            const route = location.pathname.split("/").pop();
            pathTitle = route.charAt(0).toUpperCase() + route.slice(1);
        }
        setTitle(pathTitle);
        fetchUserData();
    }, [location]);

    const fetchUserData = async () => {
        try {
            const token = Cookies.get('accessToken')
            const response = await fetch('https://auth-digi-crm.foobar.in/auth/get-user', {
                method: 'GET',
                headers: {
                    'authorization': token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setUserData(data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    return (
        <>
            <div className="h-screen flex overflow-hidden relative">
                <div className="xs:flex hidden ">
                    <Sidebar />
                </div>
                <div
                    // style={{ width: "calc(100% - 300px)" }}
                    className="bg-blue-50 w-[100%]"
                >
                    <Header title={title} />
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default DefaultLayout;