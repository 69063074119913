import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import DefaultLayout from '../layout/DefaultLayout';
import Cookies from 'js-cookie';

const RequireAuth = () => {
    const location = useLocation();
    const accessToken = Cookies.get('accessToken');

    return !accessToken ? 
    <Navigate to="/" state={{ from: location }} replace />
    :
    <DefaultLayout /> 
}

export default RequireAuth;