import { lazy } from 'react';
const LoginEmail = lazy(() => import('../components/Login/LoginEmail/LoginEmail'));
const Register = lazy(() => import('../components/Register/Register'));
const Login = lazy(() => import('../components/Login/LoginPhone/Login'));
const ForgetPassword = lazy(() => import('../components/ForgetPassword/ForgetPassword'));
const VerifyEmail = lazy(() => import('../components/ForgetPassword/VerifyEmail'));
const Newpassword = lazy(() => import('../components/ForgetPassword/Newpassword'));
const Dashboard = lazy(() => import('../pages/Dashboard/index'));
const Pipeline = lazy(() => import('../pages/Pipeline/Pipeline'));
const People = lazy(() => import('../components/People/index'));
const Companies = lazy(() => import('../components/Companies/index'));
const UserSettings = lazy(() => import('../components/UserSettings/index'));
const Integrations = lazy(() => import('../components/Integrations/index'));
const Help = lazy(() => import('../components/Help/index'));
const VerifyOTP = lazy(() => import('../components/ForgetPassword/VerifyOTP'));
const CreateEditLead = lazy(() => import('../components/Pipeline/createEditLead'));
const LeadList = lazy(() => import('../components/Leads/Listview/ListView'));
const TitleView = lazy(() => import('../components/Leads/TitleView/TitleView'));
const Contacts = lazy(() => import('../components/Pipeline/Contacts/ContactList'));


const SendEmail = lazy(() => import('../components/SendEmail/SendEmail'));
const CreateDeals = lazy(() => import('../components/Pipeline/createDeals'));
const EditDealFrom = lazy(() => import('../components/Deals/DealEditForm'));
const EditDealCRMVideo = lazy(() => import('../components/CRM Videos/CRMvideo'));


const DashboardIcon = lazy(() => import('../assets/images/dashboard.svg'));
const PipelineIcon = lazy(() => import('../assets/images/pipline.svg'));
const ContactList = lazy(() => import('../components/Pipeline/CreateEditContact'))
const EditLead = lazy(() => import('../components/Pipeline/EditLead'))
const AccountEditForm = lazy(() => import('../components/Accounts/AccountEditForm'));

export const loginRoutes = [
    { path: "/", component: LoginEmail },
    { path: "/register", component: Register },
    { path: "/login", component: Login },
    { path: "/forgetPassword", component: ForgetPassword },
    { path: "/verifyEmail", component: VerifyEmail },
    { path: "/newPassword", component: Newpassword },
    { path: "/verifyOtp", component: VerifyOTP},
]

export const privateRoutes = [
    {
        path: "/dashboard", component: Dashboard, name: "Dashboard", icon: DashboardIcon,
    },

    {
        path: "/pipeline", name: "Pipeline", children: [
            { path: "/pipeline", name: "Pipeline", component: Pipeline, icon: PipelineIcon, isVisible: true },
            { path: "/pipelinelist", component: People, isVisible: false },
            { path: "/pipeline/createlead", component: CreateEditLead, isVisible: false },
            { path: "/pipeline/kanban", component: CreateEditLead, isVisible: false },
            { path: "/pipeline/tile", component: CreateEditLead, isVisible: false },
            { path: "/pipeline/table", component: CreateEditLead, isVisible: false },
            { path: "/pipeline/customer", component: CreateEditLead, isVisible: false },
            { path: "/pipeline/lead/list", component: LeadList, isVisible: true },
            { path: "/pipeline/lead/card/sendemail", component: SendEmail, isVisible: true },


            // dynamic pipleine route
            {path: "/pipeline/abc/:subPath", component: TitleView, isVisible: false },

            { path: "/pipeline/createdeals", component: CreateDeals, isVisible: true },
            { path: "/pipeline/editdeals", component: EditDealFrom, isVisible: true },
            { path: "/pipeline/crmvideos", component: EditDealCRMVideo, isVisible: true },

            { path: "/pipeline/createcontact", component: ContactList, isVisible: false },
            { path: "/pipeline/contacts", component: Contacts, isVisible: false },
            { path: "/pipeline/editlead", component: EditLead, isVisible: false },
            { path: "/pipeline/edit-account", component: AccountEditForm, isVisible: false },



        ]
    },
    
    {
        path: "/contacts", name: "Contacts", children: [
            { path: "/contacts/people", name: "People", component: People, icon: PipelineIcon, isVisible: true },
            { path: "/contacts/companies", name: "Companies", component: Companies, icon: PipelineIcon, isVisible: true },
        ]
    },
    {
        path: "/settings", name: "Settings", children: [
            { path: "/settings/userSettings", name: "User Settings", component: UserSettings, icon: PipelineIcon, isVisible: true },
            { path: "/settings/integrations", name: "Integrations", component: Integrations, icon: PipelineIcon, isVisible: true },
        ]
    },
    {
        path: "/help", component: Help, name: "Help", icon: PipelineIcon,
    },

]