import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    accessToken: null,
    refreshToken: null,
    loading: false,
    error: null,
};

export const loginAsync = createAsyncThunk(
    'auth/login',
    async (values, { rejectWithValue }) => {
        const url = `https://auth-digi-crm.foobar.in/auth/login`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const responseData = await response.json();
            console.log('auth', responseData)

            if (response.ok) {
                return responseData.data;
            } else {
                return rejectWithValue('Failed to login');
            }
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.accessToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.error = null;
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default authSlice.reducer;
