import React, { useEffect, useRef, useState } from "react";
import { BiBell } from "react-icons/bi";
import { TbMessage2 } from "react-icons/tb";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CiSearch } from "react-icons/ci";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { BsList } from "react-icons/bs";
import Sidebar from "../Sidebar";


const notificationData = [
    { key: '1', data: 'hello ' },
    { key: '2', data: 'world' },
    { key: '3', data: 'hello' },
    { key: '4', data: 'world' },
    { key: '5', data: 'hello' },
    { key: '6', data: 'world' },
    { key: '7', data: 'hello' },
];

const Headerr = ({ title }) => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const filterRef = useRef(null);


    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const readAllNotification = () => {
        toast.success('Notifications read');
    };
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const closeSidebar = () => {
        setShowSidebar(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className="header relative" ref={filterRef}>
                <div className="w-full h-[88px] bg-white pl-2 pr-4 py-3 flex justify-between items-center relative">
                    <div className="flex justify-center items-center space-x-2">
                        <span className="xs:hidden flex ">
                            <BsList
                                className="start-14 top-8"
                                color="#000000"
                                size="25px"
                                onClick={toggleSidebar}
                            // onClick={() => setCollapsed(!collapsed)}
                            />
                        </span>
                        <span className="text-2xl pl-6 font-medium leading-8 text-slate-950">{title}</span>
                    </div>
                    <div className=" items-center gap-4 flex " >
                        <div className="relative smm:flex hidden" >
                            <CiSearch className="absolute top-1/2 left-3 pr-1 font-bold transform -translate-y-1/2 text-gray-400 w-6 h-6 font-200" />
                            <input
                                type="text"
                                id="small-input"
                                placeholder="Find People Doc. More"
                                className="h-[40px] mm:w-[360px] ms:w-[210px] ss:w-[100px] smm:w-[42px] text-16px flex  p-2 pl-10 border rounded-3xl bg-[#F2F6FE] placeholder-gray-400"
                            />
                            <HiOutlineSquares2X2 className="absolute mm:flex hidden top-1/2 left-72 bg-white rounded-lg font-bold transform -translate-y-1/2 text-gray-400 w-6 h-6 font-200" />

                        </div>
                        <button onClick={toggleNotifications} className="relative flex justify-center items-center">
                            <BiBell className="text-2xl text-gray-500" />
                            {notificationData.length > 0 && (
                                <span className="absolute -top-2 -right-2 bg-red-500 rounded-full w-4 h-4 flex justify-center items-center text-white text-xs">{notificationData.length}</span>
                            )}
                        </button>
                        <button className="flex justify-center items-center">
                            <TbMessage2 className="text-2xl text-gray-500" />
                        </button>
                    </div>
                </div>
                {showNotifications && (
                    <div
                        className="fixed rounded-2xl overflow-y-auto no-scrollbar h-[450px] top-0 w-[350px] right-0 mr-14 mt-[88px] z-50 bg-white"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                    >
                        <div className="sticky top-0 bg-white flex justify-between items-center py-[12px] px-[16px]">
                            <h4 className="m-0 text-[16px] text-[#202020] font-[600]">Notifications</h4>
                            <span
                                className="text-[12px] text-[#0b61c2] cursor-pointer"
                                onClick={() => {
                                    readAllNotification();
                                }}
                            >
                                Mark all as read
                            </span>
                        </div>
                        {notificationData?.length !== 0 ? (
                            notificationData?.map((data, key) => {
                                return (
                                    <div
                                        key={key}
                                        onClick={() => {
                                            // readAllNotification({ id: data.notificationID });
                                            readAllNotification();
                                        }}
                                    >
                                        <div className="pt-[12px] flex flex-col  pr-[24px] pl-[16px] border-t-2 border-[#dod6e5] text-[14px] text-[#4e5f7a] ">
                                            <div> <strong className="text-[#202020] font-[500] ">Exciting news!</strong></div>
                                            <div> {data.data} </div>
                                        </div>
                                        <div className="pt-[0px] pr-[24px] pb-[9px] pl-0 text-right text-[10px] ">A Moment Ago</div>
                                    </div>
                                );
                            })

                        ) : (
                            <div >
                                <div className="p-[40px]">
                                    No Notifications
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {showSidebar && (
                    <div className="fixed top-0 left-0 w-full h-full bg-black opacity-30 z-10" onClick={closeSidebar}></div>
                )}

                <div
                    className={`${showSidebar ? "left-0" : "-left-[320px]"
                        } fixed top-0 h-full w-[320px] xs:hidden z-10 transition-all duration-300 ease-in-out`}
                // onClick={handleFilterClick}
                >
                    {/* {showSidebar && (
                        <div className="fixed top-0 left-0 h-full w-64 bg-white z-10">
                            <div className="absolute z-10 top-0 xs:hidden flex" style={{ animation: 'slideInLeft 0.5s forwards' }}> */}
                    <Sidebar onClose={closeSidebar} />
                    {/* </div>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default Headerr;
