import React from 'react';
import { Outlet } from 'react-router-dom';
import LoginImage from '../assets/images/Login.png';
import DC from '../assets/images/DC.svg';
import Cookies from 'js-cookie';
import DefaultLayout from './DefaultLayout';

function AuthLayout() {
    const accessToken = Cookies.get('accessToken');
    
    return (
        <>
       
            <div className='flex flex-col md:flex-row'>
                <div className='w-full md:w-1/2'>
                    <img src={LoginImage} alt="Login" className='w-full h-auto md:h-screen object-cover' />
                </div>
                <div className='flex justify-center items-center w-full md:w-1/2 text-center bg-[#FAFBFF]'>
                    <div className='flex flex-col items-center'>
                        <div className='flex flex-row items-center mb-10'>
                            <img src={DC} alt="DC Logo" className='mr-2 p-1 aspect-square bg-[#D5C6FB] rounded-lg' />
                            <h1 className='text-[20px] font-[500] '>DigiCRM</h1>
                        </div>
                        <div className='border rounded-lg shadow-lg p-4 w-full '>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AuthLayout;