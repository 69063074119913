import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import DashboardIcon from "../../assets/images/dashboard.svg";
import PipelineIcon from "../../assets/images/pipline.svg";
import ContactsIcon from "../../assets/images/contacts.svg";
import SettingsIcon from "../../assets/images/settings.svg";
import HelpIcon from "../../assets/images/Help.svg";
import LogoutIcon from "../../assets/images/logout.svg";
import { BsList } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import { debounce } from "lodash";
 
const Sidebar = ({ onClose }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [userData, setUserData] = useState(null);
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
 
    useEffect(() => {
        // console.log('location', location.pathname);
            if (!location.pathname.includes("/pipeline")) {
                localStorage.removeItem("selectedView");
            }
    }, [location.pathname]);
 
    const getUserData = async () => {
        const accessToken = Cookies.get("accessToken");
        try {
            const response = await axios.get(
                "https://auth-digi-crm.foobar.in/auth/get-user", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken
                },
            }
            );
            setUserData(response.data.data.user);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
 
    React.useEffect(() => {
        getUserData();
    }, []);
 
    const handleLogout = async () => {
        const accessToken = Cookies.get("accessToken");
        const headers = {
            authorization: accessToken,
        };
        try {
            const response = await axios.get("https://auth-digi-crm.foobar.in/auth/signout",
                { headers }
            );
            if (response.ok) {
                navigate('/')
                Cookies.remove('accessToken')
                Cookies.remove('refreshToken')
            }
            else {
                console.log('Error Signing Out! Please Check Your Internet Connection.');
            }
        } catch (error) {
            console.error(error);
        }
    };
 
    const toggleCollapsed = debounce(() => {
        setCollapsed(!collapsed);
    }, 300);
 
    const handleSidebarClose = () => {
        onClose();
    };
 
    return (
        <div className="">
            <div className="bg-violet-950 relative">
                <div className="h-[88px] flex justify-between items-center px-4 border-b border-solid border-b-[color:var(--Base-70,#62708D)]">
                    <div className="flex gap-3">
                        <span className="flex justify-center items-center px-1 h-8 text-base font-bold bg-violet-200 rounded-lg aspect-square text-violet-950">
                            DC
                        </span>
                        {!collapsed && (
                            <span className={`transition-all duration-300 ease-in-out w-[180px] grow my-auto text-xl font-medium leading-7 text-white`}>
                                DigiCRM
                            </span>
                        )}
                        
                    </div>
                    {!collapsed && (
                        <BsList
                            color="#FFFFFF"
                            className=" start-14 top-8 xs:flex hidden"
 
                            size="25px"
                            onClick={toggleCollapsed}
                        />
                    )}
                    {collapsed && (
 
                        <div>
                            <BsList
                                className="absolute start-14 z-20 ml-8 top-8 xs:flex hidden"
                                color="#000000"
                                size="25px"
                                onClick={() => setCollapsed(!collapsed)}
                            />
                        </div>
                    )}
                    <RxCross2 className="flex xs:hidden start-14 top-8"
                        color="#FFFFFF"
                        size="25px"
                        onClick={handleSidebarClose}
                    />
 
 
                </div>
                {/* second */}
                <div
                    className="flex flex-col justify-between"
                    style={{ height: "calc(100vh - 88px)" }}
                >
                    <div className="px-4 py-7">
                        <ul>
                            <Link to="/dashboard">
                                <li className={`flex items-center gap-3 px-3 py-2 ${location.pathname.startsWith("/dashboard") ? "bg-violet-600 text-white rounded-3xl" : "text-slate-200"}`}>
                                    <img
                                        loading="lazy"
                                        src={DashboardIcon}
                                        className="self-start w-5 aspect-square"
                                        alt="dashboardicon"
                                    />
                                    {!collapsed && (
                                        <span>Dashboard</span>
                                    )}
                                </li>
                            </Link>
                            <Link to="/pipeline">
                                <li className={`flex items-center gap-3 px-3 py-2 mt-1 ${location.pathname.startsWith("/pipeline") ? "bg-violet-600 text-white rounded-3xl" : "text-slate-200"}`}>
 
                                    <img
                                        loading="lazy"
                                        src={PipelineIcon}
                                        className="self-start w-5 aspect-square"
                                        alt="pipeline-icon"
                                    />
                                    {!collapsed && (<span>Pipeline</span>)}
                                </li>
                            </Link>
                            <Link to="/contacts/people">
                                <li className={`flex items-center justify-between px-3 py-2 mt-2 ${location.pathname === "/contacts/people" ? "bg-violet-600 text-white rounded-3xl" : "text-slate-200"}`}>
                                    <div className="flex gap-3">
                                        <img
                                            loading="lazy"
                                            src={ContactsIcon}
                                            className="w-5 aspect-square"
                                            alt="contact-icon"
                                        />
                                        {!collapsed && (<span>Contacts</span>)}
                                    </div>
                                    {!collapsed && (
                                        <FaAngleDown />
                                    )}
                                </li>
                            </Link>
                            <li className={`flex items-center justify-between px-3 py-2 mt-2 ${location.pathname === "/settings" ? "bg-violet-600 text-white" : "text-slate-200"}`}>
                                <div className="flex gap-3">
                                    <img
                                        loading="lazy"
                                        src={SettingsIcon}
                                        className="w-5 aspect-square"
                                        alt="setting-icon"
                                    />
                                    {!collapsed && (
                                        <span>Settings</span>
                                    )}
                                </div>
                                {!collapsed && (
                                    <FaAngleDown />
                                )}
                            </li>
                            <li className={`flex items-center gap-3 px-3 py-2 mt-1 ${location.pathname === "/help" ? "bg-violet-600 text-white" : "text-slate-200"}`}>
                                <img
                                    loading="lazy"
                                    src={HelpIcon}
                                    className="self-start w-5 aspect-square"
                                    alt="help-icon"
                                />
                                {!collapsed && (<span>Help</span>)}
                            </li>
                        </ul>
                    </div>
                    {/* third */}
                    <div className="flex justify-between text-white px-4 py-5 border-t border-solid border-t-[color:var(--Base-70,#62708D)]">
                        <div className="flex gap-3">
                            <div className="w-8 h-8 bg-violet-200 rounded-3xl" />
                            {!collapsed && (<div className="flex-auto my-auto">
                                {userData ? userData.name : "Loading..."}
                            </div>
                            )}
                        </div>
                        <button onClick={() => setShowLogoutPopup(true)}>
                            <img
                                loading="lazy"
                                src={LogoutIcon}
                                className="my-auto w-6 aspect-square"
                                alt="logout-icon"
                            />
                        </button>
                    </div>
                </div>
                {showLogoutPopup && (
                    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center z-[9999]"
                        onClick={() => setShowLogoutPopup(false)}
                    >
                        <div
                            className="bg-white p-8 rounded-lg h-[204px] gap-3 w-[396px] flex flex-col items-center justify-center "
                        >
                            <p className="text-[20px]">Are you sure you want to logout?</p>
                            <div className="flex justify-center mt-4">
                                <button
                                    className="bg-red-500  text-white px-4 py-2 rounded mr-4"
                                    onClick={() => {
                                        handleLogout()
                                    }}
                                >
                                    <h1 className="text-[17px]">YES</h1>
                                </button>
                                <button
                                    className="bg-gray-300 px-4 py-2 rounded"
                                    onClick={() => setShowLogoutPopup(false)}
                                >
                                    <h1 className="text-[17px]">NO</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};
 
export default Sidebar;