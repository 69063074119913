// const { createSlice , nanoid } = require ("@reduxjs/toolkit");
import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

const initialState = {
users:[],
}


const dataSlice = createSlice({
    name:"dataSlice",
    initialState,
    reducers:{

        //this will not be having the other data the recent slected data will have this data
        selectUser:(state, action) => {
            state.users = [];  
            const data = {
                id:nanoid(),
                name:action.payload
            }
            state.users.push(data);
        },
        removerUser:(state, action) => {
            const data = state.users.filter((item) => {
                return item.name == action.payload
            })
            state.users.push(data);
        }
    }
});

export const {selectUser, removerUser} = dataSlice.actions; 


export default dataSlice.reducer;