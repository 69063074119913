import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { loginRoutes, privateRoutes } from './routes';
// import DefaultLayout from './layout/DefaultLayout';
import AuthLayout from './layout/AuthLayout';
import RestrictAuth from './PrivateRoutes/restrictAuth';
import RequireAuth from './PrivateRoutes/requireAuth';

const AppRouter = () => {

    const renderRoutes = (routes) => {
        return routes?.map((route, index) => {
            const { path, component: Component } = route;
            if (route.hasOwnProperty("children")) {
                return route.children?.map((childRoute, ind) => {
                    const { path: childPath, component: ChildComponent } = childRoute;
                    return (
                        <Route key={ind} path={childPath} element={
                            <React.Suspense fallback={<>Loading...</>}>
                                <ChildComponent />
                            </React.Suspense>
                        } />
                    )
                })
            } else {
                return (
                    <Route key={index} path={path} element={
                        <React.Suspense fallback={<>Loading...</>}>
                            <Component />
                        </React.Suspense>
                    } />
                )
            }
        })
    }

    return (
        <Routes>

            <Route element={<RequireAuth />}>
                {renderRoutes(privateRoutes)}
            </Route>
                <Route path='/' element={<AuthLayout />}>
                    {loginRoutes?.map(({ path, component: Component }, index) => (
                        <Route key={index} path={path} element={
                            <React.Suspense fallback={<>Loading...</>}>
                                <Component />
                            </React.Suspense>
                        } />
                    ))}
                </Route>
        </Routes>

    );
}

export default AppRouter;
